@tailwind base;
@tailwind components;
@tailwind utilities;

.centered-label {
  display: inline-flex;
  justify-content: space-evenly;
}

.full-screen__loader {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5000;
  width: 100%;
  height: 100%;
  margin: auto;
  outline: 0;
  background-color: rgba(79, 79, 79, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
}
::-webkit-scrollbar {
  display: none;
}

/* .linkNav {
  @apply p-2.5 flex rounded-md gap-6 items-center font-bold cursor-pointer duration-300;
}

.linkNav.active {
  @apply bg-[#EAF4E0] text-[#1e3702cc];
}
.linkNav:hover {
  @apply bg-[#EAF4E0] text-[#1e3702cc];
} */  

.linkNav {
  @apply p-2.5 flex rounded-md gap-6 items-center cursor-pointer duration-300;
}

.linkNav.active {
  @apply bg-blue-100 text-blue-600;
}
.linkNav:hover {
  @apply bg-blue-100 text-blue-600;
}

/*toggle */

.toggle {
  display: flex;
  align-items: center;
  justify-content: center;
}

.checkbox {
  display: none;
}

.inputStyle {
  width: 45px !important;
  height: 45px;
  border-radius: 7px;
  border-width: 1px;
  margin-left: 15px;
  margin-right: 15px;
  background: transparent;
  font-size: 20px;
  text-align: center;
  flex-wrap: wrap;
}

.label {
  position: relative;
  background: hsl(217, 57%, 51%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 50px;
  padding: 5px;
  margin: 0 20px;
  width: 50px;
  height: 26px;
  cursor: pointer;
}

.name {
  font-size: 14px;
  color: hsl(234, 10%, 19%);
}

.ball {
  background: #fff;
  position: absolute;
  top: 2px;
  left: 2px;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  transition: transform 0.15s linear;
}

.checkbox:checked + .label .ball {
  transform: translateX(24px);
}


.content h2 {
  padding: 0px 0 5px 0px;
}
.content p {
  width: 100%;
  height: 100%;
}




.bloc-tabs {
  display: flex;
  border-bottom: 2px solid #ddd;
  background: #f8f9fa;
}

.tabs {
  flex: 1;
  padding: 12px 16px;
  text-align: center;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  color: #333;
  background: #f0f0f0;
  transition: all 0.3s ease-in-out;
  border-radius: 6px;
}

.tabs:hover {
  background: #e3e3e3;
  color: #003f5c;
}

.tabs:not(:last-child) {
  margin-right: 5px;
}

.active-tabs {
  background: #003f5c; /* Updated primary color */
  color: white;
  
}

.active-tabs::before {
  content: "";
  display: block;
  position: absolute;
  top: -5px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% + 2px);
  height: 5px;
  background: #003f5c; /* Updated green shade */
}

button {
  border: none;
  outline: none;
}


.content {
  display: none;
}

.active-content {
  display: block;
}

/* Calendar Start */

.react-calendar {
  max-width: 100%;
  background: white;
  line-height: 1.125em;
}

.react-calendar--doubleView {
  width: 700px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}

.react-calendar button:enabled:hover {
  cursor: pointer;
}

.react-calendar__navigation {
  display: flex;
  height: 44px;
  margin-bottom: 1em;
}

.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}

.react-calendar__navigation button:disabled {
  background-color: #f0f0f0;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}

.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
}

.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  font-weight: bold;
}

.react-calendar__month-view__days__day--weekend {
  color: #d10000;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}

.react-calendar__tile {
  max-width: 100%;
  border-radius: 50%;
  padding: 10px 6.6667px;
  background: none;
  text-align: center;
  line-height: 16px;
}

.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #e6e6e6;
}

.react-calendar__tile--now {
  background: #76baff;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #76baff;
}

.react-calendar__tile--hasActive {
  background: #76baff;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}

.react-calendar__tile--active {
  background: #006edc;
  color: white;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #1087ff;
}

.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}

/*Calendar End*/

#canvas-container {
  height: 60vh;
  width: auto;
  position: relative;
}

@media (min-width: 768px) {
  #canvas-container {
    height: auto;
    width: auto;
  }
}

.highlight {
  background-color: #ef8c4d;
  color: #fff;
}

.payment-form label {
  color: #000;
  font-weight: 300;
  letter-spacing: 0.025em;
}

.payment-form button {
  white-space: nowrap;
  border: 0;
  outline: 0;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  padding: 0 14px;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  color: #fff;

  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  text-decoration: none;
  border-radius: 25px;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
  margin-top: 10px;
}

button:hover {
  color: #fff;
  cursor: pointer;
  background-color: #7795f8;
  transform: translateY(-1px);
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
}

.payment-form input::placeholder {
  color: #aab7c4;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  @apply appearance-none; /* Remove spinner for Chrome, Safari, Edge, and Opera */
  margin: 0;
}

input[type='number'] {
  @apply appearance-none; /* Remove spinner for Firefox */
}

/* New Homepage Styles */

/*
  Based on TailwindCSS recommendations,
  consider using classes instead of the `@apply` directive
  @see https://tailwindcss.com/docs/reusing-styles#avoiding-premature-abstraction
*/
/*-------------------------------------------------
GLOBALS CLASSES
-------------------------------------------------*/
* {
  @apply m-0 p-0;
}
body {
  font-family: 'Open Sans', sans-serif;
}
img {
  @apply border-0;
}
h1 {
  @apply font-[normal];
  font-family: 'Oswald', sans-serif;
}
h2 {
  @apply font-[normal] text-base;
  font-family: 'Open Sans', sans-serif;
}
h3 {
  @apply font-[normal] text-sm text-white;
  font-family: 'Open Sans', sans-serif;
}
/*h2{font-family: 'Oswald', sans-serif; font-weight:normal; font-size:16px;}
*/
p {
  @apply text-[13px] pb-5;
  font-family: 'Open Sans', sans-serif;
}

/*h2{font-family: 'Open Sans', sans-serif;}
*/ /*--------------------------------------- 
GENERAL CLASS
----------------------------------------*/
.marRight30 {
  @apply mr-[30px];
}
.martop {
  @apply mt-[30px];
}
.marbottom30 {
  @apply mb-[30px];
}
.padding-bottom {
  @apply pb-2.5;
}
.floatRight {
  @apply float-right;
}
.floatLeft {
  @apply float-left;
}
.clear {
  @apply clear-both;
}
.row {
  @apply overflow-hidden mx-auto my-0;
}
.page {
  @apply w-full mx-auto my-0;
  background: #1c263c;
}
.wrapper {
  @apply w-full mx-auto my-0;
  background: #1c263c;
}
.panel {
  @apply overflow-hidden;
}
.buttons {
  @apply w-20 h-[30px];
}
.text-field {
  @apply w-[200px] h-4 text-[13px] text-white p-2.5 border-[none];
  background: #91a3c2;
  font-family: 'Open Sans', sans-serif;
}
.textarea {
  @apply w-[200px] h-[106px] text-[13px] text-white overflow-auto p-2.5 border-[none];
  background: #91a3c2;
  font-family: 'Open Sans', sans-serif;
}
/*-------------------------------------------------
SOCIAL NETWORKING CLASSES
-------------------------------------------------*/
.social-wrapper {
  @apply h-[65px] pt-[25px];
  background: #1c263c;
}
.social-wrapper .row ul {
  @apply w-[175px] float-right;
  list-style: none;
}
.social-wrapper .row ul li {
  @apply float-left pr-[15px];
}
.social-wrapper .row ul li.no-padding {
  @apply float-left pr-0;
}
/*-------------------------------------------------
MENU  CLASSES
-------------------------------------------------*/
.header {
  @apply h-[120px] overflow-hidden py-[30px];
  background: #313c53;
}
.logo {
  @apply float-left w-96;
}
.logo h1 {
  @apply text-[35px] text-white;
}
.menu {
  @apply float-right w-auto;
}
.menu ul li {
  @apply w-auto float-left;
  list-style: none;
}
.menu ul li a {
  @apply float-left no-underline text-white text-sm block h-[60px] leading-[60px] px-5 border-r-[#f97316] border-r border-solid hover:text-[#313c53];
  background: #fb923c;
}
.menu ul li a:hover {
  background: #fdba74;
}
.menu ul li a.active {
  @apply text-[#313c53];
  background: #fdba74;
}
/*-------------------------------------------------
BANNER  CLASSES
-------------------------------------------------*/
.banner-wrapper {
  @apply w-full mx-auto my-0 ;
}
.banner-wrapper .row {
  background: #1c263c;
}
.banner-wrapper .controller {
  @apply float-right py-[30px];
}
.banner-wrapper .controller ul li {
  @apply float-left pr-2.5;
  list-style: none;
}
.banner-wrapper .banner {
  @apply w-full mx-auto my-0;
}
.banner .banner-bg {
  @apply w-full h-auto px-[60px] py-0;
}
.banner-bg .panel {
  @apply float-left;
}
.banner-bg .panel .title h1 {
  @apply text-[#1c263c];
}
.banner-bg .panel .content p {
  @apply text-xl text-[#768297] pt-[30px];
}
.banner-button {
  @apply w-[200px] h-[50px] text-center mt-[35px] border-l-[#fb923c] border-l-[10px] border-solid;
  background: #455268;
}
.banner-bg .panel .banner-button:hover {
  @apply border-l-[#455268] border-l-[10px] border-solid;
  background: #fb923c;
}
.banner-button a {
  @apply text-[#b6c0d2] leading-[50px] text-center no-underline text-lg hover:text-[#455268] hover:leading-[50px] hover:text-center;
  font-family: 'Oswald', sans-serif;
}
.banner-bg .banner-img {
  @apply float-left w-[458px];
}

/*-------------------------------------------------
BOX CLASSES
-------------------------------------------------*/
.boxs1 {
  @apply float-left w-[263px] px-[30px] py-[50px];
  background: #202b43;
}
.boxs2 {
  @apply float-left w-[263px] px-[30px] py-[50px];
  background: #25314d;
}
.boxs3 {
  @apply float-left w-[263px] px-[30px] py-[50px];
  background: #2a3856;
}
.title h1 {
  @apply text-[#1c263c];
}
.title h2 {
  @apply text-[#1c263c];
}
.panel-img {
  @apply w-[260px] mx-auto my-0 px-0 py-5;
}
.content p {
  @apply text-[#54657b];
}
.controller .buttons {
  @apply leading-[30px] text-center float-left hover:leading-[30px];
  background: #fb923c;
}
.controller .buttons:hover {
  background: #91a3c2;
}
.controller .buttons a {
  @apply no-underline text-[#1c263c] hover:text-[#fb923c];
}
/*-------------------------------------------------
CONTENT CLASSES
-------------------------------------------------*/
.content_wrap {
  @apply mx-auto my-0 pt-[30px] pb-0 px-[30px];
}
/*-------------------------------------------------
LEFT-PANEL CLASSES
-------------------------------------------------*/
.left-panel {
  @apply w-[900px] float-left mr-[35px];
}
.left-panel .panel {
  @apply mb-[30px] pt-[30px] pb-10 px-[30px] border-[#24314c] border-solid border-[1px];
  background: #202b43;
}
.left-panel .panel .title {
  @apply mb-[30px] pb-5 border-b-[#25314c] border-b-[1px] border-solid;
}
.panel .title .icons {
  @apply w-[60px] h-[60px] float-left pr-5;
}
.left-panel .panel .content img {
  @apply float-left mr-[30px];
}
/*-------------------------------------------------
RIGHT-PANEL CLASSES
-------------------------------------------------*/
.right-panel {
  @apply w-[280px] float-left;
}
.right-panel .panel {
  @apply overflow-auto mb-[30px] p-[30px] border-[#24314c] border-solid border-[1px];
  background: #202b43;
}
.right-panel .panel .title {
  @apply pb-5 border-b-[#25314c] border-b-[1px] border-solid;
}
.right-panel .panel .content ul {
  @apply pl-0;
}
.right-panel .panel .content ul li {
  @apply leading-[35px] border-b-[#25314c] border-b-[1px] border-solid;
  list-style: url(https://hbt-seva.s3.ap-south-1.amazonaws.com/arrow-small.jpg)
    inside;
}
.right-panel .panel .content ul li a {
  @apply no-underline text-[13px] text-[#54657b] hover:text-[#fb923c];
  font-family: 'Open Sans', sans-serif;
}
.right-panel .contact-panel {
  @apply p-[30px];
  background: #fb923c;
}
.contact-panel .title {
  @apply pb-5 border-b-[#fdba74] border-b-[1px] border-solid;
}
.contact-panel .title h1 {
  @apply text-[#1c263c];
}
.contact-panel .title h2 {
  @apply text-[#1c263c];
}
.contact-panel .form {
  @apply pt-[5px];
}
.contact-panel .form ul {
  @apply pl-0;
}
.contact-panel .form ul li {
  @apply pt-3.5;
  list-style: none;
}
.contact-panel .buttons {
  @apply leading-[30px] text-center float-left mt-5 hover:leading-[30px];
  background: #1c263c;
}
.contact-panel .buttons:hover {
  background: #91a3c2;
}
.contact-panel .buttons a {
  @apply no-underline text-[#fb923c] hover:text-[#1c263c];
}
.search {
  @apply mt-5 border-b-[none];
}
.search ul li {
  @apply float-left block border-[none];
  list-style: none;
}
.libg {
  @apply w-[180px] indent-[5px];
  background: #91a3c2;
}
.search-filed {
  @apply w-[180px] h-9 float-left indent-[5px] text-[13px] text-white border-[none];
  background: none repeat scroll 0 0 transparent;
  font-family: 'Open Sans', sans-serif;
}
.contact-panel.padding-bottm {
  @apply pb-5;
}
.address .panel {
  @apply text-[#91a3c2] border-[none];
}
.address .panel .title h1 {
  @apply text-[#fb923c] pb-2.5;
}
.address .panel .content p {
  @apply text-[#91a3c2];
}
.address .panel .content p span {
  @apply text-[#91a3c2];
}

/*-------------------------------------------------
PORTFOLIO CLASSES
-------------------------------------------------*/
.portfolio {
  @apply overflow-hidden w-[900px] mx-auto my-0;
}
.portfolio .title h2 {
  @apply text-sm font-[normal] mb-[30px] pt-2.5 pb-5;
}
.portfolio .panel {
  @apply w-[278px] float-left mb-[30px] border-[none] border-[#24314c] border-solid border-[1px];
  background: #202b43;
}
.portfolio .panel .content {
  @apply pb-5 p-2.5;
}
.portfolio .panel .content p {
  @apply pb-5;
}
.portfolio .panel .content p span {
  @apply text-[#fb923c] block pb-2.5 border-b-[#24314c] border-b border-solid;
}
.portfolio .panel .content a {
  @apply text-[#1c263c] no-underline font-[normal] text-[13px] p-[7px];
  background: #fb923c;
  font-family: 'Open Sans', sans-serif;
}
.portfolio .panel .content a:hover {
  background: #91a3c2;
}
.portfolio .panel .content img {
  @apply pb-2.5;
}

/*-------------------------------------------------
FOOTER CLASSES
-------------------------------------------------*/
.footer-wrapper {
  @apply mt-10;
  background: #313c53;
}
.footer {
  @apply w-full mx-auto my-0;
}
.footer .panel {
  @apply float-left w-[290px] pl-[30px] py-[20px] border-r-[#38445a] border-r-[1px] border-solid;
  background: none;
}
.footer .panel.title {
  background: none;
}
.footer h1 {
  @apply text-[22px] font-[normal];
}
.footer h3 {
  @apply text-[22px] font-[normal] text-white;
}
.footer .panel.title h2 {
  @apply text-base text-[#fb923c] pt-[15px];
}
.footer .panel .content {
  @apply pt-[30px];
}
.footer .panel .content p {
  @apply text-[#54657b] leading-[22px] pb-0;
}
.footer .panel .content p a {
  @apply text-[#54657b] no-underline hover:text-white;
}
.footer .panel .content p a.active {
  @apply text-white;
}
.footer .panel .content p h2 {
  @apply text-[25px] text-white;
}
.footer .panel.border-right {
  @apply border-r-[none];
}

#carousel {
  @apply w-[458px] h-[498px] overflow-hidden relative text-center mx-auto my-0;
}
#slides {
  @apply overflow-hidden relative w-[458px] h-[498px];
  /* fix ie overflow issue */
}
/* remove the list styles, width : item width * total items */
#slides ul {
  @apply relative w-[458px] m-0 p-0 left-0 top-0;
  list-style: none;
}
/* width of the item, in this case I put 250x250x gif */
#slides li {
  @apply w-[458px] h-[498px] float-left block absolute;
}

/* New Homepage Styles */


.footer-link {
  @apply text-gray-100 hover:text-gray-300  hover:font-medium transition-all duration-300 relative inline-block;
}

.footer-link::after {
  content: "";
  @apply absolute left-1/2 bottom-0 w-0 h-[2px] bg-gray-400 transition-all duration-300 transform -translate-x-1/2;
}

.footer-link:hover::after {
  @apply w-full;
}

.footer-link:active {
  @apply opacity-70;
}

.pvtTable {
  @apply bg-[#EAF4E0] border border-gray-300 text-gray-800 w-full text-sm;
}

