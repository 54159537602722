
.table-header .MuiTableCell-root {
    color: white !important;
    font-weight: 500 !important;
    padding: 4px 6px !important; /* Reduced vertical & horizontal padding */
    font-size: 13px !important;
    text-align: start;
    font-family: 'Montserrat', sans-serif;
    background: #003f5c !important;
    border: 1px solid #d5d5d5;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; 
  }
  .sno-column {
    width: 10px !important;
    text-align: center !important;
    font-size: 12px !important;
    white-space: wrap;
  }
  
  .MuiTableSortLabel-root:hover,
  .MuiTableSortLabel-root.Mui-active,
  .MuiTableSortLabel-root.Mui-active .MuiTableSortLabel-icon {
    color: white !important;
  }
  
  .MuiList-root.MuiMenu-list {
    display: flex !important;
    align-items: start;
    flex-direction: column;
    
  }
  
  .MuiTablePagination-selectLabel,
  .MuiTablePagination-displayedRows {
    margin-bottom: 5px;
    font-family: 'Montserrat', sans-serif;
  }
  
  /* Table Body */
  .table-body .MuiTableCell-root {
    text-align: start;
    font-family: 'Montserrat', sans-serif;
    border: 1px solid #d5d5d5;
    padding: 4px 6px !important; /* Reduced padding */
    font-size: 12px !important;
    white-space: nowrap;
   
  }
  
  /* Make the last row's bottom border blue */
  .table-body tr:last-child .MuiTableCell-root {
    border-bottom: 1px solid #003f5c !important; /* Blue bottom border */
  }
  
  .timesheet-table-body .MuiTableCell-root {
    padding: 12px;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    border: 1px solid #d5d5d5;
  }
  
  /* Alternate row background */
  /* Light green shade */
  /* .table-body .MuiTableRow-root:nth-child(even) {
    background-color: #edf7e4; 
  } */
  
  .cursor-p {
    cursor: pointer;
  }
  
  .table-link {
    color: black;
    cursor: pointer;
  }
  
  .verticalContent {
    writing-mode: vertical-lr;
    transform: rotate(180deg);
  }
  
  .processType {
    background-color: #1fab9f;
    color: white;
  }
  
  .kpi-form .MuiTableCell-root {
    border: none;
    padding: 6px;
  }
  
  .cursor-pointer {
    cursor: pointer;
  }
  
  
  
  
  
  /* Pagination Container */
  /* .MuiTablePagination-root {
    display: flex;
    justify-content: space-between;
    align-items: center; 
    padding: 10px 16px;
    font-family: 'Manrope', sans-serif;
    background-color: #f9f9f9;
    border-top: 1px solid #ddd;
  } */
  
  /* Align "Entries per page" Label */
  .MuiTablePagination-selectLabel {
    font-size: 14px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    color: #333;
    margin-right: 8px;
    margin-top: 20px;
    display: flex;
    align-items: center; /* Fix vertical alignment */
  }
  
  /* Align Dropdown Properly */
  .MuiTablePagination-select {
    padding: 4px 10px;
    font-size: 14px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    border-radius: 6px;
    background-color: #fff;
    border: 1px solid #ccc;
    cursor: pointer;
    display: flex;
    align-items: center; /* Fix vertical alignment */
  }
  
  /* Align "Showing X-Y of Z entries" Text */
  .MuiTablePagination-displayedRows {
    font-size: 14px;
    font-weight: 500;
    font-family: 'Montserrat', sans-serif;
    color: #555;
    margin-left: 8px;
    margin-top: 20px;
    display: flex;
    align-items: center; /* Fix vertical alignment */
  }
  
  