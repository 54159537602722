.crossIcon {
  left: 92px;
  top: -8px;
  cursor: pointer;
}

.copyIcon {
  border: 2px solid gray;
  border-radius: 20px;
  padding: 4px;
  background: gray;
  color: white;
}


/* Map Tooltip */



.marker .tooltip {
background: #fff;
bottom: 100%;
color: black;
display: block;
left: -45px;
margin-bottom: 15px;
opacity: 0;
padding: 10px;
pointer-events: none;
position: absolute;
width: 120px;
-webkit-transform: translateY(10px);
  -moz-transform: translateY(10px);
  -ms-transform: translateY(10px);
   -o-transform: translateY(10px);
    transform: translateY(10px);
-webkit-transition: all .25s ease-out;
  -moz-transition: all .25s ease-out;
  -ms-transition: all .25s ease-out;
   -o-transition: all .25s ease-out;
    transition: all .25s ease-out;
-webkit-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
  -moz-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
  -ms-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
   -o-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
}

/* This bridges the gap so you can mouse into the tooltip without it disappearing */
.marker .tooltip:before {
bottom: -20px;
content: " ";
display: block;
height: 20px;
left: 0;
position: absolute;
width: 100%;
}

/* CSS Triangles - see Trevor's post */
.marker .tooltip:after {
border-left: solid transparent 10px;
border-right: solid transparent 10px;
border-top: solid #1496bb 10px;
bottom: -10px;
content: " ";
height: 0;
left: 50%;
margin-left: -13px;
position: absolute;
width: 0;
}

.marker:hover .tooltip {
opacity: 1;
pointer-events: auto;
-webkit-transform: translateY(0px);
  -moz-transform: translateY(0px);
  -ms-transform: translateY(0px);
   -o-transform: translateY(0px);
    transform: translateY(0px);
}


.descText {
  display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
}

.mapListToggleSection {
  position: fixed;
  bottom: 0;
  width: calc(100% - var(--scrollbar-gutter,0%));
  z-index: 8;
  pointer-events: none;
  display: grid;
  grid-template: repeat(2,auto) / repeat(3,33.333%);
  grid-template-areas:
      'left center right'
      'bottom bottom bottom';
}

.mapListBtnSection {
  margin-bottom: 40px;
  grid-area: center;
  justify-self: center;
  align-self: end;
}

.mapListBtn {
  -webkit-box-pack: center !important;
    -webkit-box-align: center !important;
    appearance: none !important;
    display: inline-flex !important;
    border-radius: 24px !important;
    border: 1px solid rgba(0, 0, 0, 0.08) !important;
    outline: none !important;
    padding: 14px 19px !important;
    color: #fff !important;
    cursor: pointer !important;
    touch-action: manipulation !important;
    align-items: center !important;
    justify-content: center !important;
    box-shadow: none !important;
    transition: -ms-transform 0.25s ease 0s, -webkit-transform 0.25s ease 0s, transform 0.25s ease 0s !important;
    white-space: nowrap !important;
    pointer-events: auto !important;
    background: rgb(34, 34, 34) !important;
}

@media (max-width: 992px) { 
  .mapListBtnSection {
    margin: 10px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;    
  }
  .mapListBtn {
    padding: 5px 9px !important;
    font-size: 12px;
  }
}

.nav-item {
  @apply relative text-white text-sm font-medium font-montsettat tracking-[0.2em] hover:text-blue-100 transition-all duration-300 ease-in-out;
}

.nav-item::after {
  content: '';
  @apply absolute bottom-0 left-0 w-0 h-[1px] mt-1 bg-gray-100 transition-all duration-300 ease-in-out;
}

.nav-item:hover::after {
  @apply w-full;
}